import React, { useState } from "react";
import Nav from "../../components/nav/nav";
import Header from "../../components/UI/header/header";
import ThemeProvider from "../../style/themeProvider";
import GlobalStyle from "../../style/global";

import LayoutMain from "./layoutStyle";

const Layout = ({ children, auth }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  let navListItems = [
    { title: "Hem", path: "/" },
    { title: "Snapsvisor", path: "/snapsvisor" },
    { title: "Logga in", path: "/login" },
  ];

  if (auth) {
    navListItems = [
      { title: "Hem", path: "/" },
      { title: "Snapsvisor", path: "/snapsvisor" },
      { title: "Administrera", path: "/admin" },
      { title: "Logga ut", path: "/logout" },
    ];
  }

  return (
    <ThemeProvider>
      <GlobalStyle />
      <Header toggleMenu={() => setToggleMenu(!toggleMenu)} show={toggleMenu} />
      <Nav
        navListItems={navListItems}
        show={toggleMenu}
        toggleMenu={() => setToggleMenu(!toggleMenu)}
      />
      <LayoutMain onClick={() => setToggleMenu(false)}>{children}</LayoutMain>
    </ThemeProvider>
  );
};

export default Layout;
