import React from "react";
import { BurgerWrapper, Burger } from "./burgerMenuStyle";

export default ({ toggleMenu, showMenu }) => {
  return (
    <BurgerWrapper onClick={toggleMenu}>
      <Burger morph={showMenu}>
        <span></span>
        Menu
      </Burger>
    </BurgerWrapper>
  );
};
