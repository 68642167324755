import styled from "styled-components";
import { BurgerWrapper } from "./burgerMenu/burgerMenuStyle";

export const HeaderEl = styled.header`
  background-color: ${(props) => props.theme.colors.headerSecond};
  background: ${(props) =>
    `linear-gradient( 45deg, 
    ${props.theme.colors.headerFirst} 50%, 
    ${props.theme.colors.headerSecond} 80%, 
    ${props.theme.colors.headerThird} 56%)`};

  height: 5rem;
  display: flex;
  align-items: center;
  z-index: 202;
  position: relative;

  a {
    color: #333;
    text-decoration: none;
  }

  ${BurgerWrapper} {
    position: absolute;
    right: 0;
  }

  @media (min-width: 575px) {
    justify-content: center;
    height: 7rem;
  }
`;

export const H1 = styled.h1`
  margin: 0;
  letter-spacing: 0.2em;
  text-indent: 1rem;
  color: ${(props) => props.theme.colors.onyx};
`;
