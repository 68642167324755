export const INIT_FETCH_SONGS = "INIT_FETCH_SONGS";
export const SET_SONGS = "SET_SONGS";
export const FETCH_SONGS_START = "FETCH_SONGS_START";
export const FETCH_SONGS_END = "FETCH_SONGS_END";

export const INIT_ADD_SONG = "INIT_ADD_SONG";
export const ADD_SONG_START = "ADD_SONG_START";
export const ADD_SONG_END = "ADD_SONG_END";
export const ADD_SONG_FAIL = "ADD_SONG_FAIL";

export const INIT_REMOVE_SONG = "INIT_REMOVE_SONG";
export const REMOVE_SONG_START = "REMOVE_SONG_START";
export const REMOVE_SONG_END = "REMOVE_SONG_END";
export const REMOVE_SONG_FAIL = "REMOVE_SONG_FAIL";

export const INIT_CLEAR_SONGBOOKS = "INIT_CLEAR_SONGBOOKS";
export const CLEAR_SONGBOOKS_START = "CLEAR_SONGBOOKS_START";
export const CLEAR_SONGBOOKS_END = "CLEAR_SONGBOOKS_END";
export const CLEAR_SONGBOOKS_FAIL = "CLEAR_SONGBOOKS_FAIL";

export const INIT_AUTH = "INIT_AUTH";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const INIT_AUTH_LOGOUT = "INIT_AUTH_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_STTIMEOUT";

export const INIT_SONGBOOK_ID_HANDLER = "INIT_ADD_SONGBOOK_ID_HANDLER";
export const ADD_SONGBOOK_ID = "ADD_SONGBOOK_ID";
export const REMOVE_SONGBOOK_ID = "REMOVE_SONGBOOK_ID";
export const INIT_CLEAR_SONGBOOK = "INIT_CLEAR_SONGBOOK";
export const CLEAR_SONGBOOK = "CLEAR_SONGBOOK";

export const INIT_ADD_SONGBOOK = "INIT_ADD_SONGBOOK";
export const ADD_SONGBOOK_START = "ADD_SONGBOOK_START";
export const ADD_SONGBOOK_END = "ADD_SONGBOOK_END";
export const ADD_SONGBOOK_FAIL = "ADD_SONGBOOK_FAIL";

export const INIT_GET_SONGBOOK = "INIT_GET_SONGBOOK";
export const GET_SONGBOOK_START = "GET_SONGBOOK_START";
export const GET_SONGBOOK_END = "GET_SONGBOOK_END";
export const GET_SONGBOOK_FAIL = "GET_SONGBOOK_FAIL";
