import { takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import {
  initFetchSongs,
  initAddSong,
  initRemoveSong,
  initSongbookIdHandler,
  initClearSongbook,
  initAddSongbook,
  initGetSongbook,
  initClearSongbooks,
} from "./songsSaga";
import {
  initAuth,
  logoutSaga,
  checkAuthTimeoutSaga,
  authCheckStateSaga,
} from "./authSaga";

export function* watchSongs() {
  yield takeEvery(actionTypes.INIT_FETCH_SONGS, initFetchSongs);
  yield takeEvery(actionTypes.INIT_ADD_SONG, initAddSong);
  yield takeEvery(actionTypes.INIT_REMOVE_SONG, initRemoveSong);
  yield takeEvery(actionTypes.INIT_SONGBOOK_ID_HANDLER, initSongbookIdHandler);
  yield takeEvery(actionTypes.INIT_CLEAR_SONGBOOK, initClearSongbook);
  yield takeEvery(actionTypes.INIT_ADD_SONGBOOK, initAddSongbook);
  yield takeEvery(actionTypes.INIT_GET_SONGBOOK, initGetSongbook);
  yield takeEvery(actionTypes.INIT_CLEAR_SONGBOOKS, initClearSongbooks);
}

export function* watchAuth() {
  yield takeEvery(actionTypes.INIT_AUTH, initAuth);
  yield takeEvery(actionTypes.INIT_AUTH_LOGOUT, logoutSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
  yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
}
