import React from "react";
import { Link } from "react-router-dom";

import BurgerMenu from "./burgerMenu/burgerMenu";
import { HeaderEl, H1 } from "./headerStyle";

const Header = ({ toggleMenu, show }) => {
  return (
    <HeaderEl onClick={show ? toggleMenu : () => {}}>
      <Link to="/">
        <H1>[Snaps-vis]</H1>
      </Link>
      <BurgerMenu toggleMenu={toggleMenu} showMenu={show} />
    </HeaderEl>
  );
};

export default Header;
