import { put, select } from "redux-saga/effects";
import { axiosDb, axiosAPI } from "../../axios-instance";
import * as actions from "../actions";

export function* initFetchSongs(action) {
  yield put(actions.fetchSongsStart());
  try {
    const res = yield axiosDb.get("/songs.json");
    let content =
      res.data === null
        ? []
        : Object.keys(res.data).map((id) => {
            if (res.data[id].category === "åretrunt") {
              res.data[id].category = "året runt";
            }
            return { ...res.data[id], id: id, active: false };
          });
    yield put(actions.setSongs(content));
    yield put(actions.fetchSongsEnd());
  } catch (error) {
    yield console.warn(error);
    yield put(actions.fetchSongsEnd());
  }
}

export function* initAddSong(action) {
  yield put(actions.addSongStart());
  try {
    yield axiosDb.post(`/songs.json?auth=${action.token}`, action.songData);
    yield put(actions.addSongEnd());
    yield initFetchSongs();
  } catch (error) {
    yield console.warn(error);
    yield put(actions.addSongFail(error));
  }
}

export function* initRemoveSong(action) {
  yield put(actions.removeSongStart());
  try {
    yield axiosDb.delete(`/songs/${action.id}.json?auth=${action.token}`);
    yield put(actions.removeSongEnd());
    yield initFetchSongs();
  } catch (error) {
    yield console.warn(error);
    yield put(actions.removeSongFail(error));
  }
}

export function* initSongbookIdHandler(action) {
  const songbook = yield select((state) => state.songs);
  if (!songbook.songIds.includes(action.id)) {
    yield put(actions.addSongbookId(action.id, action.title));
    let songActivity = yield songbook.songs.map((item) =>
      item.id === action.id ? { ...item, active: true } : item
    );
    yield put(actions.setSongs(songActivity));
  } else {
    let songActivity = yield songbook.songs.map((item) =>
      item.id === action.id ? { ...item, active: false } : item
    );
    yield put(actions.setSongs(songActivity));
    const songIds = songbook.songIds.filter((id) => id !== action.id);
    const songs = songbook.songbookSongs.filter(
      (song) => song.id !== action.id
    );
    yield put(actions.removeSongbookId(songIds, songs));
  }
}

export function* initClearSongbook(action) {
  const songbook = yield select((state) => state.songs);
  yield put(actions.clearSongbook());
  let songActivity = yield songbook.songs.map((item) => {
    return {
      ...item,
      active: false,
    };
  });
  yield put(actions.setSongs(songActivity));
}

export function* initAddSongbook(action) {
  if (action.songbook.length !== 0) {
    yield put(actions.addSongbookStart());
    try {
      const res = yield axiosAPI.post(`/save`, action.songbook);
      yield put(actions.addSongbookEnd(res.data.code));
      yield put(actions.initGetSongbook(res.data.code));
    } catch (error) {
      yield console.warn(error);
      yield put(actions.addSongbookFail(error));
    }
  }
}

export function* initGetSongbook(action) {
  yield put(actions.getSongbookStart());
  try {
    const res = yield axiosAPI.get(`/songbook/${action.code}`);
    yield put(actions.getSongbookEnd(res.data, action.code));
  } catch (error) {
    yield console.warn(error);
    yield put(actions.getSongbookFail(error));
  }
}

export function* initClearSongbooks(action) {
  yield put(actions.clearSongbooksStart());
  try {
    yield axiosDb.delete(`/songbooks.json?auth=${action.token}`);
    yield put(actions.clearSongbooksEnd());
  } catch (error) {
    console.warn(error.message);
    yield put(actions.clearSongbooksFail(error));
  }
}
