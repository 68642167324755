import React from "react";
import { Spinner, Bounce1, Bounce2 } from "./spinnerStyle";

const spinner = () => (
  <Spinner>
    <Bounce1></Bounce1>
    <Bounce2></Bounce2>
  </Spinner>
);

export default spinner;
