import axios from 'axios';

export const axiosDb = axios.create({
  baseURL: 'https://snapsvis.firebaseio.com/',
});

export const axiosAPI = axios.create({
  baseURL: 'https://snapsvis-api.herokuapp.com/api/v1/',
  headers: { key: 'enHemligSnapsNyckel' },
});
