import React, { lazy, Suspense, useEffect, useCallback } from "react";
import Layout from "./hoc/layout/layout";
import Spinner from "./components/UI/spinner/spinner";
import Logout from "./containers/auth/logout/logout";
/* import Songs from "./containers/songs/songs"; */

import { Route, Switch, Redirect } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import * as action from "./store/actions";

const Songbook = lazy(() => {
  return import("./containers/songbook/songbook");
});

const Songs = lazy(() => {
  return import("./containers/songs/songs");
});

const Auth = lazy(() => {
  return import("./containers/auth/auth");
});

const Admin = lazy(() => {
  return import("./containers/admin/admin");
});

const App = () => {
  const dispatch = useDispatch();
  const checkAuth = useCallback(() => dispatch(action.authCheckState()), [
    dispatch,
  ]);
  const auth = useSelector((state) => state.auth.token !== null);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  let routes = (
    <Switch>
      <Route path="/login" render={(props) => <Auth {...props} />} />
      <Route path="/snapsvisor" render={(props) => <Songs {...props} />} />
      <Route path="/" component={Songbook} />
      <Redirect to="/" />
    </Switch>
  );

  if (auth) {
    routes = (
      <Switch>
        <Route path="/login" render={(props) => <Auth {...props} />} />
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Route path="/snapsvisor" render={(props) => <Songs {...props} />} />
        <Route path="/logout" component={Logout} />
        <Route path="/" component={Songbook} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Layout auth={auth}>
      <Suspense fallback={<Spinner />}>{routes}</Suspense>
    </Layout>
  );
};

export default App;
