import React, { useEffect, useCallback } from "react";
import * as actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(actions.logout()), [dispatch]);

  useEffect(() => {
    logout();
  }, [logout]); 

  return <Redirect to="/" />;
};

export default Logout;
