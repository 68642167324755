import styled from "styled-components";

export const BurgerWrapper = styled.div`
  height: 5rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Burger = styled.div`
  position: relative;
  display: block;
  width: 30px;
  height: 20px;

  background: transparent;
  color: ${(props) => props.theme.colors.burger};
  font-size: 0;

  transition: all 0.25s ease-in-out;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;

    position: absolute;
    top: 50%;
    left: 50%;

    background: currentColor;
    border-radius: 2px;

    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-in-out;
  }

  &:before {
    transform: ${(props) =>
      props.morph
        ? "translate(-50%, -50%) rotate(45deg)"
        : "transform: translate(-50%, -50%);"};
  }
  &:after {
    transform: ${(props) =>
      props.morph
        ? "translate(-50%, -50%) rotate(-45deg)"
        : "transform: translate(-50%, -50%);"};
  }

  span:before,
  span:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    left: 50%;

    border-radius: 2px;

    background: currentColor;
    color: ${(props) => props.theme.colors.burger};

    opacity: ${(props) => (props.morph ? "0" : "1")};
    height: ${(props) => (props.morph ? "0px" : "4px")};
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-in-out,
      height 0.25s cubic-bezier(0.8, 0.8, 0.2, 0.7);
  }

  & span:before {
    top: 0%;
  }
  & span:after {
    top: 100%;
  }
  @media (min-width: 575px) {
    display: none;
  }
`;
