import styled from "styled-components";

export const Li = styled.li`
  list-style-type: none;
  display: block;

  a {
    text-indent: 1rem;
    padding: 0.5rem 0 0 0.5rem;
    display: block;
    width: 80%;
    height: 100%;
    text-decoration: none;
    color: ${(props) => props.theme.colors.onyx};
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: 700;
  }

  a.active {
    ${"" /* color: ${(props) => props.theme.colors.red};
    text-shadow: 1px 1px 1px ${(props) => props.theme.colors.yellow}; */}
    position: relative;

    &::before,
    &::after {
      content: "";
      border-bottom: 2px solid ${(props) => props.theme.colors.onyx};
      display: block;
      width: 0.5em;
      height: 2rem;
      position: absolute;
      border-radius: 2%;
    }
    &::before {
      border-left: 2px solid ${(props) => props.theme.colors.onyx};
      top: 0;
      left: 1rem;
      transform: rotate(2deg);
    }
    &::after {
      border-left: 2px solid ${(props) => props.theme.colors.onyx};
      top: 0.5rem;
      left: 0.8rem;
      transform: rotate(-4deg);
    }
  }

  @media (min-width: 575px) {
    a {
      width: 100%;
      padding: 1rem;
      text-indent: 0;
    }

    a.active {
      &::before,
      &::after {
        border-left: none;
        border-bottom: 1px solid ${(props) => props.theme.colors.onyx};
        width: 100%;
        height: 45%;
        border-radius: 0;
      }
      &::before {
        top: 54%;
        left: 54%;
        transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(-1deg);
        border-radius: 1% 4% 2% 4% / 2% 4% 5% 4%;
      }
      &::after {
        top: 52%;
        left: 52%;
        transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(1deg);
        border-radius: 1% 4% 2% 4% / 2% 4% 5% 4%;
      }
    }
  }
`;
