import React from "react";
import NavItem from "./navItems/navItem";

import { Nav, Ul } from "./navStyle";

const nav = ({ navListItems, show, toggleMenu }) => {
  return (
    <Nav visible={show}>
      <Ul onClick={toggleMenu}>
        {navListItems.map((item, i) => (
          <NavItem
            key={item.title}
            link={item.path}
            title={item.title}
            toggleMenu={toggleMenu}
          />
        ))}
      </Ul>
    </Nav>
  );
};

export default nav;
