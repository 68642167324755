import * as actionTypes from "./actionTypes";

export const setSongs = (songs) => {
  return {
    type: actionTypes.SET_SONGS,
    songs: songs,
  };
};

export const initFetchSongs = () => {
  return {
    type: actionTypes.INIT_FETCH_SONGS,
  };
};

export const fetchSongsStart = () => {
  return {
    type: actionTypes.FETCH_SONGS_START,
  };
};

export const fetchSongsEnd = () => {
  return {
    type: actionTypes.FETCH_SONGS_END,
  };
};

export const initAddSong = (songData, token) => {
  return {
    type: actionTypes.INIT_ADD_SONG,
    songData: songData,
    token: token,
  };
};

export const addSongStart = () => {
  return {
    type: actionTypes.ADD_SONG_START,
  };
};

export const addSongEnd = () => {
  return {
    type: actionTypes.ADD_SONG_END,
  };
};

export const addSongFail = (error) => {
  return {
    type: actionTypes.ADD_SONG_FAIL,
    error: error,
  };
};

export const initRemoveSong = (token, id) => {
  return {
    type: actionTypes.INIT_REMOVE_SONG,
    token: token,
    id: id,
  };
};

export const removeSongStart = () => {
  return {
    type: actionTypes.REMOVE_SONG_START,
  };
};

export const removeSongEnd = () => {
  return {
    type: actionTypes.REMOVE_SONG_END,
  };
};

export const removeSongFail = (error) => {
  return {
    type: actionTypes.REMOVE_SONG_FAIL,
    error: error,
  };
};

export const initClearSongbook = () => {
  return {
    type: actionTypes.INIT_CLEAR_SONGBOOK,
  };
};

export const clearSongbook = () => {
  return {
    type: actionTypes.CLEAR_SONGBOOK,
  };
};

export const addSongbookId = (id, title) => {
  return {
    type: actionTypes.ADD_SONGBOOK_ID,
    id: id,
    title: title,
  };
};

export const removeSongbookId = (songIds, songs) => {
  return {
    type: actionTypes.REMOVE_SONGBOOK_ID,
    songIds: songIds,
    songbookSongs: songs,
  };
};

export const initSongbookIdHandler = (id, title) => {
  return {
    type: actionTypes.INIT_SONGBOOK_ID_HANDLER,
    id: id,
    title: title,
  };
};

export const initAddSongbook = (songbook) => {
  return {
    type: actionTypes.INIT_ADD_SONGBOOK,
    songbook: songbook,
  };
};

export const addSongbookStart = () => {
  return {
    type: actionTypes.ADD_SONGBOOK_START,
  };
};

export const addSongbookEnd = (code) => {
  return {
    type: actionTypes.ADD_SONGBOOK_END,
    code: code,
  };
};

export const addSongbookFail = (error) => {
  return {
    type: actionTypes.ADD_SONGBOOK_FAIL,
    songbookError: error,
  };
};

export const initGetSongbook = (code) => {
  return {
    type: actionTypes.INIT_GET_SONGBOOK,
    code: code,
  };
};

export const getSongbookStart = () => {
  return {
    type: actionTypes.GET_SONGBOOK_START,
  };
};

export const getSongbookEnd = (songbook, code) => {
  return {
    type: actionTypes.GET_SONGBOOK_END,
    songbook: songbook,
    code: code,
  };
};

export const getSongbookFail = (error) => {
  return {
    type: actionTypes.GET_SONGBOOK_FAIL,
    songbookError: error,
  };
};

export const initClearSongbooks = (token) => {
  return {
    type: actionTypes.INIT_CLEAR_SONGBOOKS,
    token: token,
  };
};

export const clearSongbooksStart = () => {
  return {
    type: actionTypes.CLEAR_SONGBOOKS_START,
  };
};

export const clearSongbooksEnd = () => {
  return {
    type: actionTypes.CLEAR_SONGBOOKS_END,
  };
};

export const clearSongbooksFail = (error) => {
  return {
    type: actionTypes.CLEAR_SONGBOOKS_FAIL,
    error: error,
  };
};
