import React from "react";
import { NavLink } from "react-router-dom";

import { Li } from "./navItemStyle";

const navItem = ({ link, toggleMenu, title }) => {
  return (
    <Li>
      <NavLink exact to={link} onClick={toggleMenu}>
        {title}
      </NavLink>
    </Li>
  );
};

export default navItem;
