import React from "react";
import { ThemeProvider } from "styled-components";

const themeStyles = {
  colors: {
    white: "#fafafa",
    green: "#06B49A",
    aquaMarine: "#AFDBD2",
    lightAqua: "#7fffd4a1",
    onyx: "#36313D",

    error: "tomato",

    activeNav: "#FFFDF9",

    headerFirst: "#ffc837",
    headerSecond: "#ff8008",
    headerThird: "#ffc107",
    headerFourth: "#ff3232",

    nav: "#fafafa",
    button: "#ffc837",

    black: "#333",
    blue: "#add8e6",
    yellow: "#ffe367",
    red: "#99230a",
    redBrown: "#c59852",
    peach: "#ffc093",
    burger: "#36313D",
    background: "#fafafa",
  },
  fonts: ["sans-serif", "Roboto"],
  fontSize: {
    small: "0.9rem",
    medium: "1.08rem",
    large: "1.4rem",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={themeStyles}>{children}</ThemeProvider>
);

export default Theme;
