import styled, { keyframes } from "styled-components";

const bounceAni = keyframes`
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }`;

export const Spinner = styled.div`
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: calc(50% - 50px);
  margin: 40px auto;
  z-index: 9999;

  border-radius: 50%;
`;

const Bounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${bounceAni} 2s infinite ease-in-out;

  :before,
  :after {
    display: block;
    position: absolute;
    content: "";
    height: 102%;
    width: 102%;
    border: 2px solid ${(props) => props.theme.colors.onyx};
  }

  :before {
    border-radius: 45% 60%;
    top: -2%;
    left: -1%;
  }
  :after {
    border-radius: 45% 52%;
    top: -1%;
    left: 2%;
  }
`;

export const Bounce1 = styled(Bounce)`
  background-color: ${({ theme }) => theme.colors.headerThird};
`;

export const Bounce2 = styled(Bounce)`
  background-color: ${({ theme }) => theme.colors.headerFirst};
  animation-delay: -1s;
`;
