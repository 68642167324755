import * as actionTypes from "../actions/actionTypes";

const intitialState = {
  songs: null,
  error: null,
  loading: false,
  loaded: false,
  added: false,
  songIds: [],
  songbookSongs: [],
  songbookLoading: false,
  songbookError: null,
  songbook: [],
  code: null,
  cleared: false,
};

const removeSongStart = (state, action) => {
  return { ...state, loading: true };
};

const removeSongEnd = (state, action) => {
  return { ...state, loading: false };
};

const removeSongFail = (state, action) => {
  return { ...state, loading: false, error: action.error };
};

const clearSongbooksStart = (state, action) => {
  return { ...state, clearLoading: true };
};

const clearSongbooksEnd = (state, action) => {
  return { ...state, clearLoading: false, cleared: true };
};

const clearSongbooksFail = (state, action) => {
  return { ...state, clearLoading: false, error: action.error };
};

const addSongStart = (state, action) => {
  return { ...state, loading: true, added: false };
};

const addSongEnd = (state, action) => {
  return { ...state, loading: false, added: true };
};

const addSongFail = (state, action) => {
  return { ...state, loading: false, added: false, error: action.error };
};

const fetchSongsStart = (state, action) => {
  return { ...state, loading: true };
};

const fetchSongsEnd = (state, action) => {
  return { ...state, loading: false, loaded: true };
};

const setSongs = (state, action) => {
  return {
    ...state,
    songs: action.songs,
  };
};

const addSongbookId = (state, action) => {
  return {
    ...state,
    songIds: state.songIds.concat(action.id),
    songbookSongs: state.songbookSongs.concat({
      id: action.id,
      title: action.title,
    }),
  };
};

const removeSongbookId = (state, action) => {
  return {
    ...state,
    songIds: action.songIds,
    songbookSongs: action.songbookSongs,
  };
};

const clearSongbook = (state, action) => {
  return {
    ...state,
    songIds: [],
    songbookSongs: [],
    code: null,
  };
};

const addSongbookStart = (state, action) => {
  return { ...state, songbookLoading: true };
};

const addSongbookEnd = (state, action) => {
  return { ...state, songbookLoading: false, code: action.code };
};

const addSongbookFail = (state, action) => {
  return { ...state, songbookLoading: false, error: action.error };
};

const getSongbookStart = (state, action) => {
  return { ...state, songbookLoading: true, songbookError: null };
};

const getSongbookEnd = (state, action) => {
  return {
    ...state,
    songbookLoading: false,
    songbook: action.songbook,
    code: action.code,
  };
};

const getSongbookFail = (state, action) => {
  return {
    ...state,
    songbookLoading: false,
    songbookError: action.songbookError,
  };
};

const reducer = (state = intitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SONGS_START:
      return fetchSongsStart(state, action);
    case actionTypes.FETCH_SONGS_END:
      return fetchSongsEnd(state, action);
    case actionTypes.SET_SONGS:
      return setSongs(state, action);
    case actionTypes.ADD_SONG_START:
      return addSongStart(state, action);
    case actionTypes.ADD_SONG_END:
      return addSongEnd(state, action);
    case actionTypes.ADD_SONG_FAIL:
      return addSongFail(state, action);
    case actionTypes.REMOVE_SONG_START:
      return removeSongStart(state, action);
    case actionTypes.REMOVE_SONG_END:
      return removeSongEnd(state, action);
    case actionTypes.REMOVE_SONG_FAIL:
      return removeSongFail(state, action);
    case actionTypes.ADD_SONGBOOK_ID:
      return addSongbookId(state, action);
    case actionTypes.REMOVE_SONGBOOK_ID:
      return removeSongbookId(state, action);
    case actionTypes.CLEAR_SONGBOOK:
      return clearSongbook(state, action);
    case actionTypes.ADD_SONGBOOK_START:
      return addSongbookStart(state, action);
    case actionTypes.ADD_SONGBOOK_END:
      return addSongbookEnd(state, action);
    case actionTypes.ADD_SONGBOOK_FAIL:
      return addSongbookFail(state, action);
    case actionTypes.GET_SONGBOOK_START:
      return getSongbookStart(state, action);
    case actionTypes.GET_SONGBOOK_END:
      return getSongbookEnd(state, action);
    case actionTypes.GET_SONGBOOK_FAIL:
      return getSongbookFail(state, action);
    case actionTypes.CLEAR_SONGBOOKS_START:
      return clearSongbooksStart(state, action);
    case actionTypes.CLEAR_SONGBOOKS_END:
      return clearSongbooksEnd(state, action);
    case actionTypes.CLEAR_SONGBOOKS_FAIL:
      return clearSongbooksFail(state, action);
    default:
      return state;
  }
};

export default reducer;
