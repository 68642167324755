import { createGlobalStyle } from "styled-components";
import IBMPlexSans from "../assets/IBMPlexSans-Light.ttf";

const GlobalStyle = createGlobalStyle`
 *,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

@font-face {
     @font-face {
    font-family: 'IBM Plex Sans';
    src: url(${IBMPlexSans}) format('truetype');
    font-weight: 300;
    font-style: thin;
    font-display: auto;
  }



body {
  background-color: ${(props) => props.theme.colors.background};
    
  scrollbar-width: 11px;
  scrollbar-height: 11px;
  scrollbar-color: transparent transparent;

  ::-webkit-scrollbar {
    width: 11px;
  }
  ::-webkit-scrollbar-track {
    background: #efefef;
  }
  ::-webkit-scrollbar-thumb {
    height: 2rem;
    background: #ccc;
    border-radius: 10px 25%;
   border: 2px solid #333;
    }
  }
}
`;

export default GlobalStyle;
