import { put, delay } from "redux-saga/effects";
import { axiosDb } from "../../axios-instance";
import * as actions from "../actions";

const key = "AIzaSyC2CuPxhy2_gx2vNDit-KF_ube1A0fLfL4";

export function* initAuth(action) {
  yield put(actions.authStart());
  const authData = {
    email: action.email,
    password: action.password,
    returnSecureToken: true,
  };
  let url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${key}`;
  try {
    const res = yield axiosDb.post(url, authData);
    const expirationDate = new Date(
      new Date().getTime() + res.data.expiresIn * 1000
    );
    yield sessionStorage.setItem(
      "snapsAuth",
      JSON.stringify({
        token: res.data.idToken,
        expire: expirationDate,
        userId: res.data.localId,
      })
    );
    yield put(actions.authSuccess(res.data.idToken, res.data.localId));
    yield put(actions.checkAuthTimeout(res.data.expiresIn));
  } catch (error) {
    console.warn("ERROR: ", error);
    yield put(actions.authFail(error));
  }
}

export function* logoutSaga(action) {
  yield sessionStorage.removeItem("snapsAuth");
  yield put(actions.logoutSuceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

export function* authCheckStateSaga(action) {
  const auth = yield JSON.parse(sessionStorage.getItem("snapsAuth"));
  if (!auth) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(auth.expire);
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      yield put(actions.authSuccess(auth.token, auth.userId));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}
