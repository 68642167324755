import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  z-index: 203;
  overflow: hidden;
  position: absolute;
  top: 4.9rem;
  width: 100%;

  border-bottom-right-radius: ${(props) => (props.visible ? "0" : "40%")};
  transform: ${(props) =>
    props.visible ? "translateX(0)" : "translateX(-101%);"};
  transition: transform 0.3s ease-in-out,
    border-bottom-right-radius 0.5s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      top: 98%;
      left: 0;

      width: 100%;
      height: 0.4rem;
      display: block;
      background: ${(props) => `linear-gradient(
        60deg,
        ${props.theme.colors.headerFirst},
        ${props.theme.colors.headerSecond},
        ${props.theme.colors.headerThird},
        ${props.theme.colors.headerFourth}
      )`};
      background-size: 150% 150%;
      background-position: 0 50%;
      animation: moveGradient 4s alternate infinite;
    }

  @media (min-width: 575px) {
    transform: translateX(0);
    position: relative;
    top: 0;
    border-radius: 0;
    height: 5rem;
    margin-bottom: 1rem;
      
    &::after {
      top: 96%;
    }
    
  }
    @keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
`;

export const Ul = styled.ul`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colors.nav};
  padding: 0;
  margin: 0;
  width: 100%;

  & li {
    padding-top: 0.5rem;
  }

  & li:first-child {
    padding-top: 1rem;
  }

  & li:last-child {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 575px) {
    flex-direction: row;
    align-items: center;

    & li,
    & li:first-child,
    & li:last-child {
      padding: 0;
    }
  }
`;
